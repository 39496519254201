import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import url from './env/env'
Vue.prototype.url = url
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {
    Button,
    Toast,
    Swipe,
    SwipeItem,
    RadioGroup,
    Radio,
    NoticeBar
} from 'vant';
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
import Foot from './components/foot.vue'
Vue.component("Foot", Foot)
import back from './components/back.vue'
Vue.component("Back", back)
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.use(NoticeBar);


// import style
// import 'swiper/css/swiper.css'
// 判断是手机还是电脑端,跳转对应官网地址
Vue.prototype.setUrl = function () {
    if ((navigator.userAgent.match(
            /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
        // document.write("手机访问.");
        // window.location.href = url.wapUrl
    } else {
        // document.write("电脑访问.");
        // window.location.href = "http://www.baidu.com"
        window.location.href = url.wwwUrl
    }
}


// Vue.use(Radio);
// Vue.use(RadioGroup);
Vue.use(ElementUI);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Button);
Vue.use(Toast);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')