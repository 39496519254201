import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        // 首页
        path: '/',
        name: 'Index',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/index/index.vue')
    },
    // {
    //     // 轮播
    //     path: '/swiper',
    //     name: 'Swiper',
    //     component: () =>
    //         import ( /* webpackChunkName: "about" */ '../views/swiper.vue')
    // },
    {
        // 试听二级页面
        path: '/audition',
        name: 'Audition',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/audition/index.vue')
    },
    {
        // 试听播放页
        path: '/playVideo',
        name: 'PlayVideo',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/audition/playVideo/index.vue')
    },
    {
        // 网校名师详情页
        path: '/teacher',
        name: 'Teacher',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/teacher/index.vue')
    },
    {
        // 课程推荐页面
        path: '/course',
        name: 'Course',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/course/index.vue')
    },
    {
        // 资讯中心二级页面
        path: '/news',
        name: 'News',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/news/index.vue')
    },
    {
        // 资讯中心san级页面
        path: '/newsInfo',
        name: 'NewsInfo',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/news/newsInfo/index.vue')
    },
    {
        // 登录/注册页
        path: '/login',
        name: 'Login',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/login/login.vue')
    },
    {
        // 忘记密码
        path: '/forget',
        name: 'Forget',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/forget/index.vue')
    },
    {
        // 重置密码成功页
        path: '/jumpLogin',
        name: 'JumpLogin',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/forget/jumpLogin/index.vue')
    },
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router