let a = {
    ////正式环境
    baseUrl: "https://m.liyouedu.cn",
    imgUrl: "https://res-qd.liyouedu.cn/resources/website/wap/",
    apiUrl: "https://liyouedu.cn",
    wapUrl: "https://m.liyouedu.cn",
    wwwUrl:'https://www.liyouedu.cn'
}
let b = {
    ////测试
    baseUrl: "https://www.dev.liyouedu.cn",
    imgUrl: "https://res-qd.liyouedu.cn/resources/website/wap/",
    apiUrl: "https://api.dev.liyouedu.cn",
    wapUrl: "https://m.dev.liyouedu.cn",
    wwwUrl: "https://www.dev.liyouedu.cn"
}
let c = process.env.VUE_APP_TITLE === 'development' ? b : a
export default c;

// 正式环境
// let baseUrl = "http://m.liyouedu.cn"
// let imgUrl = "http://www.liyouedu.cn"
// let apiUrl = "http://liyouedu.cn"
// let wapUrl = "http://m.liyouedu.cn"
// let wwwUrl = "http://www.liyouedu.cn"
    
// // 测试
// let baseUrl = "http://www.dev.liyouedu.cn"
// let imgUrl = "http://www.dev.liyouedu.cn"
// let apiUrl = "http://api.dev.liyouedu.cn"
// let wapUrl = "http://m.dev.liyouedu.cn"
// let wwwUrl = "http://www.dev.liyouedu.cn"


// // 第三方js库地址
//     // let scriptUrl = "http://crm.test.com"

// export default {
//     baseUrl,
//     imgUrl,
//     apiUrl,
//     wapUrl,
//     wwwUrl
// }
