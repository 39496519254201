import http from './http'
import url from '@/env/env'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
// let resquest = "http://www.liyouedu.cn"
let resquest = url.apiUrl

// get请求
export default {
    // 课程分类
    category_list(params) {
        return http.get(`${resquest}/api/courses/category_list`, params)
    },
    // 首页
    index(params) {
        return http.get(`${resquest}/api/wap/index`, params)
    },
     // 首页图片
     index_pic(params) {
        return http.get(`${resquest}/api/pic`, params)
    },
    // 首页
    mobile_index(params) {
        return http.get(`${resquest}/api/website/index`, params)
    },
    // 课程分类
    course_cate(params) {
        return http.get(`${resquest}/api/courses/course_cate`, params)
    },
    // 课程列表接口
    courses(params) {
        return http.get(`${resquest}/api/courses`, params)
    },
    // 课程详情
    courses_detail(params) {
        return http.get(`${resquest}/api/courses/detail`, params)
    },
    // 师资 
    faculty_list_new(params) {
        return http.get(`${resquest}/api/website/faculty_list_new`, params)
    },
    // 课程推荐 
    recommmend(params) {
        return http.get(`${resquest}/api/website/recommmend`, params)
    },
    // 科目列表
    subject(params) {
        return http.get(`${resquest}/api/subject/index`, params)
    },
    // 资讯详情
    detail(params) {
        return http.get(`${resquest}/api/article/detail`, params)
    },
    // 自建站提交手机号
    landing_site(params) {
        return http.get(`${resquest}/api/landing_site/register`, params)
    },
    // 发送短信
    sms(params) {
        return http.get(`${resquest}/api/sms/send`, params)
    },
    // 注册 
    register(params) {
        return http.post(`${resquest}/api/user/register`, params)
    },
    // 登录 
    Login(params) {
        return http.post(`${resquest}/api/user/login`, params)
    },
    // 修改密码
    resetpwd(params) {
        return http.post(`${resquest}/api/user/resetpwd`, params)
    },
     // 保利威
     play_safe(params) {
        return http.get(`${resquest}/api/polyv/play_safe`, params)
    },
      // 资讯列表_v1(app+pc)
      article(params) {
        return http.get(`${resquest}/api/article/index_v1`, params)
    },
}